import { OrderItem, OrderItemStatus } from '@/features/orders';

export function getTotalAmountByStatus(
  items: OrderItem[],
  id: string,
  status: OrderItemStatus,
  originalId: string | null = null,
): number {
  return (
    items
      .filter(
        (item) =>
          item.id === id &&
          item.status === status &&
          item.originalId === originalId,
      )
      .reduce((sum, item) => sum + item.amount, 0) ?? 0
  );
}
